import { createApp } from "vue";

import VueTippy from "vue-tippy";
import I18n from "../plugins/i18n";
import store from "../store/cart";
import MiniCart from "../pages/MiniCart.vue";
import responsiveMode from "../utils/responsiveMode";

if (responsiveMode()) {
  window.responsiveMode = true;
} else {
  window.responsiveMode = false;
}

document.addEventListener("DOMContentLoaded", async function () {
  const miniCartNode = document.getElementById("mini-cart-app");

  if (!miniCartNode) return;

  const props = JSON.parse(miniCartNode.dataset.props);
  const translations = JSON.parse(miniCartNode.dataset.translations);
  const storeData = JSON.parse(miniCartNode.dataset.store);

  const app = createApp(MiniCart, props);

  app.use(store);
  app.use(I18n, translations);
  app.use(VueTippy);

  store.commit("cart/setId", storeData.state.id);
  store.commit("paths/setPaths", storeData.state.paths);
  store.commit("cart/setInitialItemCount", storeData.state.initialItemCount);
  store.commit("cart/setResponsiveMode", window.responsiveMode);
  store.commit("cart/setLoggedIn", storeData.state.loggedIn);

  // Mini Cart on every page that has nav component
  app.mount(miniCartNode);
});
