import { on, ready } from "../utils/listeners";

const loadLoginRegisterModal = async () => {
  const module = await import("../modules/loginRegisterModal");
  module.default();

  return module;
};

const loadAndShowLoginModal = async (el, e) => {
  e?.preventDefault();
  const module = await loadLoginRegisterModal();
  module.openLoginModal();
};

const loadAndShowRegisterModal = async (el, e) => {
  e?.preventDefault();
  const module = await loadLoginRegisterModal();
  module.openRegisterModal();
};

ready(() => {
  on("click", ".js-login", loadAndShowLoginModal);
  on("click", ".js-register", loadAndShowRegisterModal);
  window.addEventListener("showLoginModal", loadAndShowLoginModal);
  window.addEventListener("showRegisterModal", loadAndShowRegisterModal);
});
