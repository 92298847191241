<template>
  <div>
    <overlay
      v-show="loading"
      is-drawer-overlay
      absolutely-positioned />
    <p class="typeset-8 text-ink-4 pb-4 mb-4 border-b border-ink-1">
      {{ numberOfItemsString }}
    </p>
    <div v-if="items.length">
      <mini-cart-item
        v-for="(item, index) in items"
        :key="item.id"
        :item="item"
        :class="{
          'border-b border-ink-1 pb-4 mb-4': shouldShowBorderForCartItem(index)
        }" />
    </div>
    <div
      v-else
      class="flex flex-col items-center mt-4">
      <img
        class="aspect-ratio-1x1 mb-6"
        width="240"
        :src="emptyBasketImage" />
      <p class="mt-3 mb-6">{{ t("cart.drawer.empty") }}</p>
      <a
        v-if="loggedIn"
        class="btn btn-large btn-primary btn-block"
        :href="savedDesignsPath"
        @click="triggerAnalyticsEvent">
        {{ t("cart.drawer.view-saved-designs") }}
      </a>
      <a
        v-else
        class="btn btn-large btn-primary btn-block"
        href="#"
        @click.prevent="showLoginModal">
        {{ t("cart.drawer.log-in") }}
      </a>
      <a
        class="btn btn-large btn-secondary mt-3 btn-block"
        href="#"
        @click.prevent="$emit('closeDrawer')">
        {{ t("cart.drawer.continue-shopping") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import emptyBasketImage from "@/app/javascript/images/empty-cart.png";
import MiniCartItem from "./MiniCartItem.vue";
import Overlay from "../shared/Overlay.vue";

export default {
  name: "MiniCartItems",
  components: {
    MiniCartItem,
    Overlay
  },
  emits: ["closeDrawer"],
  data() {
    return {
      emptyBasketImage
    };
  },
  computed: {
    ...mapState({
      items: state => state.cart.items,
      loading: state => state.loading,
      loggedIn: state => state.cart.loggedIn,
      savedDesignsPath: state => state.paths.savedDesignsPath
    }),
    ...mapGetters({
      numberOfItems: "cart/numberOfItems"
    }),
    numberOfItemsString() {
      if (this.numberOfItems === 1) {
        return this.t("cart.drawer.number-of-items.single", {
          number: this.numberOfItems
        });
      }
      return this.t("cart.drawer.number-of-items.multiple", {
        number: this.numberOfItems
      });
    },
    shouldShowBorderForCartItem() {
      return index => {
        return index !== this.items.length - 1;
      };
    }
  },
  methods: {
    showLoginModal() {
      const showLoginModalEvent = new Event("showLoginModal");
      window.dispatchEvent(showLoginModalEvent);
      this.$emit("closeDrawer");
      analytics.track("Clicked Log In (Mini Cart)");
    },
    triggerAnalyticsEvent() {
      analytics.track("Clicked Saved Designs (Mini Cart)");
    }
  }
};
</script>
