<template>
  <div class="flex mini-cart-item gap-x-4">
    <div class="mini-cart-item-img-container bg-paper flex-no-shrink">
      <div class="bg-paper p-2">
        <div class="aspect-ratio-1x1">
          <img
            :src="item.imageUrl"
            :alt="item.name"
            class="w-full" />
        </div>
      </div>
    </div>
    <div class="item-details">
      <p class="typeset-6">
        <a
          v-if="item.originalProductPath"
          :href="item.originalProductPath">
          {{ item.name }}
        </a>

        <template v-else>{{ item.name }}</template>
      </p>
      <div
        v-for="(lineItem, index) in visibleLineItems"
        :key="index"
        class="mb-4">
        <p>
          <span v-if="lineItemIsEnvelope(lineItem)">
            {{ t("cart.item.envelopes.singular") }}
          </span>
          <span v-else-if="lineItem.injected_giftcard">
            {{ lineItem.name }} - {{ lineItem.price.formatted }}
          </span>
          <span v-else>{{ lineItem.name }}</span>
        </p>
        <div
          v-if="lineItem.qty"
          class="typeset-7">
          Qty: {{ lineItem.qty }}
        </div>
      </div>
      <div
        v-if="item.customised"
        class="inline-flex gap-3 items-center typeset-4 bg-pink-1 br-1 h-6 px-3 uppercase mb-4">
        {{ t("cart.item.personalised-label") }}
      </div>
      <p
        v-for="(attribute, index) in item.variantAttributeOptions"
        :key="attribute.id"
        class="typeset-8 text-ink-4"
        :class="{ 'mb-4': index === item.variantAttributeOptions.length - 1 }">
        <strong>{{ attribute.attributeName }}:</strong>
        {{ attribute.optionName }}
      </p>
      <div class="inline-flex gap-6">
        <a
          v-if="editable"
          :href="item.editPath"
          class="underline">
          {{ t("cart.item.edit") }}
        </a>
        <a
          class="cursor-pointer underline"
          @click.prevent="removeItem(item)">
          {{ t("cart.mini-cart.remove") }}
        </a>
      </div>
      <div
        v-if="!item.inStock"
        class="typeset-7 text-red mt-2">
        {{ item.stockTracking.ctaText }}
      </div>
    </div>
    <div class="ml-auto text-right">
      <p class="typeset-6">
        {{ item.price.formatted }}
      </p>
      <span
        v-if="isDiscounted"
        class="typeset-8 bg-sunflower strikethrough px-2 py-1 flex items-center justify-center ml-3">
        {{ item.basePrice.formatted }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MiniCartItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isDiscounted() {
      return this.item.basePrice.raw > this.item.price.raw;
    },
    visibleLineItems() {
      return this.item.lineItems.filter(item => {
        return !item.hidden && !item.bundle_line;
      });
    },
    lineItemIsEnvelope() {
      return lineItem => lineItem.index === "env";
    },
    editable() {
      return !!this.item.editPath && !this.item.isWeddingCredit;
    }
  },
  methods: {
    removeItem(cartItem) {
      this.$store.dispatch("cart/removeItem", cartItem);
    }
  }
};
</script>
<style scoped lang="scss">
.mini-cart-item-img-container {
  width: 74px;
  height: 74px;
}
</style>
