<template>
  <div
    v-if="items.length"
    class="p-6 shadow-2">
    <div class="px-3">
      <subtotal-deal
        v-if="showSubtotalDeal"
        :deal="deal" />
    </div>
    <div>
      <div class="px-3 pt-3 pb-6">
        <div v-if="discount.raw > 0">
          <div class="flex justify-between typeset-7 mb-3">
            <p>{{ t("cart.totals.subtotal") }}</p>
            <p>{{ subtotal.formatted }}</p>
          </div>
          <div class="flex justify-between text-green typeset-7">
            <p>{{ t("cart.totals.discounts") }}</p>
            <p>-{{ discount.formatted }}</p>
          </div>
          <hr />
          <div class="flex justify-between typeset-6">
            <p>{{ t("cart.totals.total-excluding-delivery") }}</p>
            <p>{{ total.formatted }}</p>
          </div>
        </div>
        <div
          v-else
          class="flex justify-between typeset-6">
          <p>{{ t("cart.totals.subtotal") }}</p>
          <p>{{ subtotal.formatted }}</p>
        </div>
      </div>

      <div class="flex gap-x-3 px-3">
        <a
          :href="cartPath"
          class="btn btn-secondary btn-large btn-block">
          {{ t("cart.drawer.cart") }}
        </a>
        <a
          :href="checkoutPath"
          class="btn btn-primary btn-large btn-block">
          {{ t("cart.drawer.checkout") }}
        </a>
      </div>
      <div
        v-if="!fetchedMiniCart"
        class="py-6"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SubtotalDeal from "@/app/javascript/components/shared/SubtotalDeal.vue";

export default {
  name: "MiniCartDrawerControls",
  components: {
    SubtotalDeal
  },
  props: {
    deal: {
      type: Object,
      required: true
    }
  },
  emits: ["close"],
  computed: {
    ...mapState({
      items: state => state.cart.items,
      subtotal: state => state.totals.subtotal,
      discount: state => state.totals.discount,
      total: state => state.totals.total,
      cartPath: state => state.paths.cartPath,
      checkoutPath: state => state.paths.checkoutPath,
      fetchedMiniCart: state => state.cart.fetchedMiniCart
    }),
    ...mapGetters("cart", ["showSubtotalDealOnCart"]),
    showSubtotalDeal() {
      return this.showSubtotalDealOnCart && this.deal.showOnMiniCart;
    }
  },
  mounted() {
    if (!this.fetchedMiniCart) {
      this.$store.dispatch("cart/fetchMiniCart");
    }
  }
};
</script>
